import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { CinematicCamera } from 'three/examples/jsm/cameras/CinematicCamera.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

const webglContainer = document.querySelector('.webgl');
const scene = new THREE.Scene();
const camera = new CinematicCamera(60, window.innerWidth/window.innerHeight, 1, 1000);
const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
renderer.autoClear = false;
renderer.setPixelRatio( window.devicePixelRatio );
renderer.setSize( webglContainer.offsetWidth, webglContainer.offsetHeight );

const clock = new THREE.Clock();
const textureLoader = new THREE.TextureLoader();

let maxHeight = window.innerHeight;
let composer;
let mixer, model, pivot;

// const controls = new OrbitControls( camera, renderer.domElement );

const initThree = function() {
  camera.setFocalLength(28);
  camera.position.set( 0, 0, 85 );
  camera.lookAt(new THREE.Vector3(0,0,0));
  renderer.setClearColor( 0x000000, 0 );
  renderer.setSize(window.innerWidth,window.innerHeight);

  const light = new THREE.DirectionalLight(0xf9d29d, 0.8);
  light.position.set(20, 40, 100);
  light.castShadow = true;
  light.shadow.mapSize.width = 1024;
  light.shadow.mapSize.height = 1024;
  scene.add(light);

  const ambientLight = new THREE.AmbientLight( 0xf9d29d, 0.2); // soft white light
  scene.add( ambientLight );

  const hemiLight = new THREE.HemisphereLight( 0xf9d29d, 0xffd8cb, 0.2 );
  scene.add( hemiLight );

  webglContainer.appendChild(renderer.domElement);

  window.addEventListener('resize', resize, { passive: true });

  const manager = new THREE.LoadingManager();
  manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
    console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
  };

  manager.onLoad = function ( ) {
    console.log( 'Loading complete!');
    document.documentElement.classList.add('is-ready');
  };

  manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
    console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
    let loadingPercent = Math.round(50 * (itemsLoaded / itemsTotal));
  };

  manager.onError = function ( url ) {
    console.log( 'There was an error loading ' + url );
  };

  let loader = new GLTFLoader(manager);
  loader.load('../public/models/stylized_hand_painted_scene/scene.gltf', function(object){
    model = object.scene;
    model.position.set(0,0,0);
    // model.scale.set(0.4,0.4,0.4);
    window.innerWidth > window.innerHeight ? model.scale.set(0.4,0.4,0.4) : model.scale.set(0.3,0.3,0.3);

    const box = new THREE.Box3().setFromObject( model );
    box.getCenter( model.position ); // this re-sets the model position
    model.position.multiplyScalar( - 1 );

    pivot = new THREE.Group();
    pivot.add( model );
    // pivot.position.set(20,-25,0);
    window.innerWidth > window.innerHeight ? pivot.position.set(20,-25,0) : pivot.position.set(5,-10,0);

    scene.add(pivot);
    // scene.add(model);

    renderer.shadowMap.enabled = true;

    const renderPass = new RenderPass( scene, camera );

    composer = new EffectComposer( renderer );
    composer.addPass( renderPass );

    mixer = new THREE.AnimationMixer( object.scene );
    mixer.clipAction( object.animations[ 0 ] ).play();

    maxHeight = window.innerHeight;
    resize ();
    animate();
  });
};

function render() {
  var delta = clock.getDelta();
  mixer.update( delta );

  composer.render();
}

function lerp(a, b, t) {
  return ((1 - t) * a + t * b);
}

function animate() {
  // render the 3D scene
  render();
  requestAnimationFrame( animate );
}

function resize () {
  console.log('window resized');
  // cointainer height - window height to limit the scroll at the top of the screen when we are at the bottom of the container
  maxHeight = window.innerHeight;

  const width = window.innerWidth;
  const height = window.innerHeight;

  renderer.setSize(width, height);
  composer.setSize(width, height);

  camera.aspect = width / height;
  camera.updateProjectionMatrix();
}

export { initThree };