import '../styles/index.scss';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

console.log('webpack starterkit');

import { initThree } from './threedify';

(function() {
  initThree();
})();
